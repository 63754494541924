<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <span>{{ companyId && !companyId.startsWith('57741ec0-5af4-11eb-8e20-61028293a') ? '景德镇市智慧旅游公共服务系统' : companyId && companyId.startsWith('57741ec0-5af4-11eb-8e20-61028293a') ? '瑶里景区智慧票务系统' : ''}}</span>
      </div>
      <h6>{{ userName }}, 你好</h6>
      <el-button type="primary" @click="addDialogVisible = true">修改密码</el-button>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>
    <!-- 页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside width="200px">
        <div class="toggle-button">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu
          :default-active = "activePath"
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409eff"
          :router="true"
        >
          <el-menu-item :index="resolvePath(menu.菜单路由)" @click="saveNavState(menu.菜单路由)"
          v-for="menu in menuList" :key="menu.ID">
            <template slot="title">
              <i :class="menu.菜单图标"></i>
              <span>{{menu.菜单名称}}</span>
            </template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
      <!-- 修改密码对话框 -->
      <el-dialog
        title="修改密码"
        :visible.sync="addDialogVisible"
      >
        <el-form
          label-width="100px"
          :model="loginForm"
          :rules="loginFormRules"
          ref="loginForm"
        >
          <!-- 密码 -->
          <el-form-item prop="newPassword" label="新密码">
            <el-input
              v-model="loginForm.newPassword"
              placeholder="输入新密码"
              type="password"
              prefix-icon="iconfont icon-mima"
            ></el-input>
          </el-form-item>
          <el-form-item prop="againPassword" label="确认密码">
            <el-input
              v-model="loginForm.againPassword"
              placeholder="再次确认密码"
              type="password"
              prefix-icon="iconfont icon-mima"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="changePwd">确 定</el-button>
        </span>
      </el-dialog>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "message",
  props: {
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.loginForm.againPassword !== '') {
          this.$refs.loginForm.validateField('againPassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.loginForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      menuList: [],
      activePath: '',
      userName: '',
      companyId: window.sessionStorage.getItem("companyId"),
      companyName: window.sessionStorage.getItem("companyName"),
      addDialogVisible: false,
      loginForm: {
        //登录表单的数据绑定对象
        newPassword: "",
        againPassword: "",
      },
      // 这是表单的验证规则对象
      loginFormRules: {
        // 验证密码是否合法
        newPassword: [
          { validator: validatePass, trigger: 'blur' },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        // 验证密码是否合法
        againPassword: [
          { validator: validatePass2, trigger: 'blur' },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created(){
    this.getMenuList()
    this.getSceneryAreaDetail(this.companyId)
  },
  methods: {
    async getMenuList() {
      const data = await this.$axios.get(
        "/system/getMenuList"
      );

      console.log('getMenuList', data)
      if (data.code == 200) {
        this.menuList = data.data;

        window.sessionStorage.setItem("activePath",this.$route.path)
        this.activePath = window.sessionStorage.getItem("activePath")
        this.userName = window.sessionStorage.getItem("userName")
      } else {
        this.$message.warning(data.msg);
      }
    },
    async getSceneryAreaDetail(id) {
      if(id==='0000-0000-0000-0000-0000-0001'){
        window.sessionStorage.setItem("sceneryImg", 'https://changyoucn.com/changyoucn/0cecd7f4-7265-44f7-ae24-79a84de4171f.png')
      }else {
        const data = await this.$axios.get(
          `/scenery/getSceneryAreaDetail?id=${id}`
        );

        console.log('getSceneryAreaDetail', data)
        if (data.code == 200) {
          const result = data.data
          window.sessionStorage.setItem("sceneryImg", result.图片)
        } else {
          this.$message.warning(data.msg);
        }
      }

    },
    //退出登录
    logout() {
      window.localStorage.clear();
      this.$router.push("/").catch(() => {});
    },
    //保存连接的激活状态
    saveNavState(activePath){
      window.sessionStorage.setItem('activePath',activePath)
    },
    resolvePath(routePath) {
      if (routePath) {
        return routePath
      } else {
        return this.basePath
      }
    },
    changePwd() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          const userId = window.sessionStorage.getItem("userId")
          const params = {
            userId,
            pwd: this.loginForm.newPassword
          }
          this.$axios
            .post("/user/changePwd", params)
            .then((req) => {
              console.log(req)
              if (req.code != 200) {
                return this.$message.error("修改失败");
              }
              this.$message.success("修改成功");
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style>
.home-container {
  height: 100vh;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
.el-aside {
  background-color: #333744;

}

.el-main {
  background-color: #eaedf1;
}
.el-aside .toggle-button{
  background-color: #4A5064;
  color: #fff;
  height: 30px;
  line-height: 30px;
  text-align: center;

}
</style>

